<template>
  <div>
    <!-- COTIZACIONES -->
    <iq-card class="col-md-12">
      <template v-slot:headerTitle>
        <h4 class="card-title">Cotizaciones Realizadas</h4>
      </template>
      <template v-slot:headerAction>
        <b-button
          v-if="quuotationsTable.length == 0 ? true : quuotationsTable.some((item) => item.status_value === 1)"
          v-can="'quotation.create'"
          variant="primary"
          class="mr-2"
          @click="$refs.quotationsModals.show()"
        >
          Agregar Cotización
        </b-button>
      </template>
      <template v-slot:body>
        <b-table responsive :items="quuotationsTable" :fields="date">
          <template v-slot:cell(description)="data">
            {{
              data.item.description === null
                ? "Sin descripción"
                : data.item.description
            }}
          </template>
          <template v-slot:cell(status)="data">
            <b-badge pill :variant="colorStatus(data.item.status)"
              ><h5 class="text-white">{{ data.item.status }}</h5></b-badge
            >
          </template>
          <template v-slot:cell(file_url)="data">
            <b-link
              :href="data.item.file_url"
              target="_blank"
              v-can="'quotation.view'"
              >Ver Cotización</b-link
            >
            <b-link
              v-if="
                data.item.status_value === 2 || data.item.status_value === 3
              "
              class="ml-3"
              :href="data.item.factura"
              target="_blank"
              >Ver Factura</b-link
            >
            <b-button
              v-b-tooltip.hover
              v-if="data.item.status_value === 3"
              title="Eliminar factura"
              class="ml-3 pt-0 pb-0 btn-sm"
              @click="deleteInvoice(data.item.id)"
              variant="danger"
              ><i class="ri-delete-bin-line ml-1"></i>
            </b-button>
          </template>
          <template v-slot:cell(aprobar)="data">
            <b-button
              v-if="data.item.status_value === 2 && ivoiceStatus"
              class="ml-3 pt-0 pb-0 btn-sm"
              @click="$refs.ivoiceModals.show(data.item.id)"
              variant="primary"
              >Agregar Factura
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Aprobar cotización"
              v-can="'quotation.approved'"
              v-if="data.item.status_value === 1 && enableDisableButtons"
              class="ml-3 pt-0 pb-0 btn-sm"
              @click="aprobar(data.item.id)"
              variant="primary"
              >Aprobar
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Eliminar cotización"
              v-can="'quotation.delete'"
              v-if="data.item.status_value === 1 && enableDisableButtons"
              class="ml-3 pt-0 pb-0 btn-sm"
              @click="deleteQuotations(data.item.id)"
              variant="danger"
              >Eliminar
            </b-button>
          </template></b-table
        ></template
      ></iq-card
    >
    <modal-ivoice ref="ivoiceModals" v-on:loadData="updateDates" :type="type" />
    <modal-quotations
      ref="quotationsModals"
      v-on:loadData="updateDates"
      :type="type"
      :quotation="id"
    />
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import ModalIvoice from "../modals/IvoiceModals.vue";
import ModalQuotations from "../modals/QuotationsModals.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalIvoice,
    ModalQuotations,
  },
  async mounted() {
    core.index();
    await this.updateDates();
  },
  props: {
    type: {
      type: String,
    },
    statusPurchase: {
      type: String,
    },
  },

  data() {
    return {
      id: this.$route.params.id,
      facturita: "",
      RR: true,
      AP: true,
      quuotationsTable: [],
      optionsConfirm: {
        title: "Confirmación",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "Si",
        cancelTitle: "No",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
        bodyClass: "text-center text-danger",
      },
      date: [
        { label: "Descripción", key: "description", class: "text-center" },
        { label: "Estado", key: "status", class: "text-center" },
        { label: "Documentos", key: "file_url", class: "text-center" },
        { label: "", key: "aprobar", class: "text-center" },
      ],
    };
  },
  methods: {
    async updateDates() {
      const payload = { id: this.id, type: this.type };
      this.quuotationsTable = await this.$store.dispatch(
        "quotations/listQuotationsInPurchase",
        payload
      );
      await this.$emit("loadData");
    },

    async aprobar(id) {
      const value = await this.$bvModal.msgBoxConfirm(
        "¿Desea Aprobar esta cotización?",
        this.optionsConfirm
      );
      try {
        if (value) {
          await this.$store.dispatch("quotations/aprobateQuotations", id);
          await this.updateDates();
          core.showSnackbar("success", "Cotización Aprobada");
        }
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },

    async deleteInvoice(id) {
      const value = await this.$bvModal.msgBoxConfirm(
        "¿Desea eliminar esta factura?.",
        this.optionsConfirm
      );
      try {
        if (value) {
          await this.$store.dispatch("quotations/deleteInvoice", id);
          await this.updateDates();
          core.showSnackbar("success", "Factura eliminada correctamente");
        }
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },

    async deleteQuotations(item) {
      const value = await this.$bvModal.msgBoxConfirm(
        "¿Desea eliminar este registro?.",
        this.optionsConfirm
      );
      try {
        if (value) {
          await this.$store.dispatch("quotations/destroyQuotations", item);
          await this.updateDates();
          core.showSnackbar("success", "Cotización eliminada correctamente");
        }
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    colorStatus(status) {
      let color;
      switch (status) {
        case "Pendiente de Aprobación":
          color = "warning";
          break;
        case "Facturada":
          color = "info";
          break;
        case "Cotización no Aprobada":
          color = "danger";
          break;
        case "Cotización Aprobada":
          color = "success";
          break;
      }
      return color;
    },
  },
  computed: {
    ...mapGetters({
      getPurchaseRequest: "purchase/getPurchaseRequest",
      getOrdersRequest: "orders/getOrdersRequest",
    }),
    ivoiceStatus() {
      if (this.type === "request") {
        let status;
        switch (this.getPurchaseRequest.status) {
          case 4:
            status = true;
            break;
        }
        return status;
      } else if (this.type === "order") {
        let status;
        switch (this.getOrdersRequest.status) {
          case 2:
            status = true;
            break;
        }
        return status;
      }
    },
    enableDisableButtons() {
      if (this.type === "request") {
        let status;
        switch (this.getPurchaseRequest.status) {
          case 1:
            status = true;
            break;
          case 2:
            status = true;
            break;
          case 3:
            status = false;
            break;
          case 4:
            status = false;
            break;
          case 5:
            status = false;
            break;
          case 6:
            status = true;
            break;
          case 7:
            status = false;
            break;
        }
        return status;
      } else if (this.type === "order") {
        let status;
        switch (this.getOrdersRequest.status) {
          case 1:
            status = true;
            break;
          case 2:
            status = true;
            break;
          case 3:
            status = false;
            break;
          case 4:
            status = true;
            break;
        }
        return status;
      }
    },
  },
};
</script>
