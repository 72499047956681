<template>
  <b-modal id="list" size="lg" hide-footer scrollable :title="title">
    <h5 class="text-center text-muted" v-if="comentaries == ''">
      Sin Comentarios...
    </h5>
    <ul class="timeline">
      <li v-for="(item, index) in comentaries" :key="index">
        <a href="#">{{ item.user }}</a>
        <b-row>
          <b-col md="12">
            <b-form-textarea
              id="textarea"
              :disabled="true"
              v-model="item.commentary"
              placeholder="Enter something..."
              rows="2"
              max-rows="6"
            ></b-form-textarea>
            <span class="float-right mr-3">
              <small>{{ item.date }}</small>
              <b-button
                variant="link"
                class="text-danger"
                v-b-tooltip.hover
                title="Eliminar"
                v-confirm="{
                  ok: (dialog) => remove(item.id),
                  message: '¿Ésta seguro de eliminar este comentario?',
                }"
                ><i class="ri-delete-bin-fill"></i
              ></b-button>
            </span>
          </b-col>
        </b-row>
      </li>
    </ul>
    <hr />
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
export default {
  name: "Comentaries",
  props: {
    correlative: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      comentaries: "",
      title: "",
      model: "",
      data: "",
    };
  },
  methods: {
    async show(model, data) {
      this.model = model;
      this.data = data;
      this.title =
        this.model === "sc"
          ? "Comentarios de solicitudes de compras"
          : this.model === "oc"
          ? `Comentarios de orden de compra ${this.correlative}`
          : this.model === "poc"
          ? "Comentarios de producto"
          : "";
      let payload = { model: this.model, model_id: this.data };
      let res = await this.$store.dispatch(
        "comentaries/listComentaries",
        payload
      );
      this.comentaries = res.reverse();
      this.$bvModal.show("list");
    },
    async remove(id) {
      try {
        await this.$store.dispatch("comentaries/destroyComentaries", id);
        await this.show(this.model, this.data);
        core.showSnackbar("success", "Registro eliminado correctamente");
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    sortBy(array, cfg) {
      var toString = Object.prototype.toString,
        // default parser function
        parse = function (x) {
          return x;
        },
        // gets the item to be sorted
        getItem = function (x) {
          var isObject = x != null && typeof x === "object";
          var isProp = isObject && this.prop in x;
          return this.parser(isProp ? x[this.prop] : x);
        };

      /**
       * Sorts an array of elements.
       *
       * @param {Array} array: the collection to sort
       * @param {Object} cfg: the configuration options
       * @property {String}   cfg.prop: property name (if it is an Array of objects)
       * @property {Boolean}  cfg.desc: determines whether the sort is descending
       * @property {Function} cfg.parser: function to parse the items to expected type
       * @return {Array}
       */
      if (!(array instanceof Array && array.length)) return [];
      if (toString.call(cfg) !== "[object Object]") cfg = {};
      if (typeof cfg.parser !== "function") cfg.parser = parse;
      cfg.desc = !!cfg.desc ? -1 : 1;
      return array.sort(function (a, b) {
        a = getItem.call(cfg, a);
        b = getItem.call(cfg, b);
        return cfg.desc * (a < b ? -1 : +(a > b));
      });
    },
  },
};
</script>
<style scoped>
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
</style>
