<template>
  <!-- MODAL FACTURA -->
  <b-modal
    id="modal-factura"
    ref="my-modal"
    hide-footer
    title="Agregar Factura"
  >
    <div class="p-4">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-form-group label="Factura" label-for="Factura" md="12">
            <ValidationProvider
              name="Factura"
              rules="required|file:application/pdf"
              v-slot="{ errors }"
            >
              <b-form-file
                class="p-2"
                :class="errors.length > 0 ? ' is-invalid' : ''"
                v-model="ivoice.file"
                accept=".pdf"
                browse-text=".pdf"
                placeholder="Factura"
              ></b-form-file>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <hr />
          <b-button
            class="mt-3 ml-1 float-right"
            variant="primary"
            type="submit"
            >Guardar</b-button
          >
          <b-button
            class="mt-3 float-right iq-bg-danger"
            variant="none"
            @click="$bvModal.hide('modal-factura')"
            >Cerrar</b-button
          >
        </form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";

export default {
  props: {
    type: {
      type: String,
    },
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      ivoice: {
        file: null,
      },
      quotations: "",
    };
  },
  methods: {
    show(quotations) {
      this.ivoice = {
        file: null,
      };
      this.quotations = quotations;
      this.$bvModal.show("modal-factura");
    },
    async onSubmit() {
      const userForm = new FormData();
      for (const key in this.ivoice) {
        userForm.append(key, this.ivoice[key]);
      }
      try {
        const payload = {
          id: this.quotations,
          form: userForm,
          type: this.type,
        };
        const res = await this.$store.dispatch("quotations/storeInvoice", payload);
        await this.$emit("loadData");
        this.$bvModal.hide("modal-factura");
        core.showSnackbar("success", "Factura Agregada correctamente");
      } catch (error) {
        console.log(error);
        this.$store.commit("stop");
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
};
</script>