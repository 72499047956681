<template>
  <!-- MODALS COTIZACION -->
  <div v-can="'quotation.view'">
    <b-modal
      id="cotizacion"
      ref="my-cotizacion"
      hide-footer
      title="Agregar Cotización"
    >
      <div class="p-4">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-form-group label-for="Tipo" label="Tipo (Opcional)">
              <v-select
                label="text"
                :reduce="(text) => text.id"
                v-model="form.payment_type"
                placeholder="Seleccionar..."
                :options="paymentsOptions"
              >
              </v-select>
            </b-form-group>
            <b-form-group
              label="Descripción (Opcional)"
              label-for="Descripción"
              md="12"
            >
              <b-form-input
                v-model="form.description"
                type="text"
                placeholder="Descripción de la cotización"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group label="Documento" label-for="Documento" md="12">
              <ValidationProvider
                name="Documento"
                rules="required|file:application/pdf"
                v-slot="{ errors }"
              >
                <b-form-file
                  class="p-2"
                  v-model="form.file"
                  accept=".pdf"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                  browse-text=".pdf"
                  placeholder="Archivo de Cotización"
                  drop-placeholder="Drop file here..."
                ></b-form-file>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <hr />
            <b-button
              class="mt-3 ml-1 float-right"
              variant="primary"
              type="submit"
              >Guardar</b-button
            >
            <b-button
              class="mt-3 float-right iq-bg-danger"
              variant="none"
              @click="$bvModal.hide('cotizacion')"
              >Cerrar</b-button
            >
          </form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
export default {
  async mounted() {
    core.index();
    const type = await this.$store.dispatch("payments/listPayment");
    this.paymentsOptions = type.map((item) => ({
      id: item.id,
      text: item.type,
    }));
  },
  props: {
    type: {
      type: String,
    },
    quotation: {
      quotation: String,
    },
  },
  data() {
    return {
      form: {
        payment_type: "",
        file: null,
        description: "",
      },
      paymentsOptions: [],
    };
  },
  methods: {
    show() {
      this.form = {
        payment_type: null,
        file: null,
        description: "",
      };
      this.$refs["my-cotizacion"].show();
    },
    async onSubmit() {
      try {
        this.$store.commit("run");
        const payload = {
          id: this.quotation,
          form: {
            payment_type: this.form.payment_type ? this.form.payment_type : "",
            file: this.form.file ? this.form.file : "",
            description: this.form.description ? this.form.description : "",
          },
          type: this.type,
        };
        await this.$store.dispatch("quotations/storeQuotations", payload);
        await this.$emit("loadData");
        this.$refs["my-cotizacion"].hide();
        core.showSnackbar("success", "Cotización Agregada");
      } catch (error) {
        this.$store.commit("stop");
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
};
</script>